import { useGetUtmSessionTags } from '@hooks/useUTMtags';
import { ThemeProvider } from '@mui/material';
import { FlagProvider, IConfig } from '@unleash/proxy-client-react';
import getServerFlags from 'api/getServerFlags';
import MaintenanceBanner from 'components/MaintenanceBanner';
import Script from 'next/script';
import { FC } from 'react';
import { ToastContainer } from 'react-toastify';
import RuntimeConfigProvider from 'state/runtime-config-provider';
import useSWR from 'swr';
import { theme } from 'theme/theme';
import { getCachedRuntimeConfig } from 'utils/runtimeConfigUtils';

const cashedConfig = getCachedRuntimeConfig();

const {
    NEXT_PUBLIC_UNLEASH_FRONTEND_API_URL: CASHED_UNLEASH_URL,
    NEXT_PUBLIC_UNLEASH_FRONTEND_API_TOKEN: CASHED_UNLEASH_TOKEN,
    UNLEASH_APP_NAME: CASHED_UNLEASH_NAME,
    NEXT_PUBLIC_GTM_ID: CASHED_GTM_ID,
} = cashedConfig;

const unleashConfig = {
    url: CASHED_UNLEASH_URL || 'https://not-used.com/unleash',
    clientKey: CASHED_UNLEASH_TOKEN || 'not-used',
    disableRefresh: true,
    appName: CASHED_UNLEASH_NAME || 'not-used',
} as IConfig;

interface ProvidersProps {
    children: React.ReactNode;
}

const Providers: FC<ProvidersProps> = ({ children }) => {
    const { utm_source, utm_medium, utm_campaign } = useGetUtmSessionTags();
    const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID || CASHED_GTM_ID;

    const serverFlagsFetcher = async () => {
        const { data } = await getServerFlags();
        return data;
    };

    const { data: flags } = useSWR('/flags/', serverFlagsFetcher, {
        revalidateOnFocus: false,
    });

    return (
        <RuntimeConfigProvider>
            <FlagProvider config={unleashConfig}>
                <ThemeProvider theme={theme}>
                    <Script id="GTMOnReadyScript">
                        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js',
                        'utm_source': '${utm_source || ''}',
                        'utm_medium': '${utm_medium || ''}',
                        'utm_campaign': '${utm_campaign || ''}'
                        });
                        var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
                        j.async=true;
                        j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl; 
                        '&gtm_auth=IJ-sB0Nt7mNFVoFrvsYtVw&gtm_preview=env-1&gtm_cookies_win=x';
                        f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer', '${GTM_ID}');`}
                    </Script>

                    <MaintenanceBanner {...flags} canBeClosed={true} />

                    {children}

                    <ToastContainer />
                </ThemeProvider>
            </FlagProvider>
        </RuntimeConfigProvider>
    );
};

export default Providers;
