import { IconProps } from './types';

const CloseIcon: React.FC<IconProps> = ({
    width,
    height = '100%',
    color = 'black',
    isCursorPointer,
    onClick,
    style,
}) => {
    return (
        <svg
            data-testid="close-icon"
            onClick={onClick}
            style={{ cursor: isCursorPointer ? 'pointer' : 'auto', ...style }}
            width={width}
            height={height}
            viewBox="0 0 15 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.4755 2.425C14.5597 2.33884 14.6265 2.23653 14.6721 2.12391C14.7177 2.0113 14.7412 1.89058 14.7413 1.76865C14.7414 1.64672 14.718 1.52598 14.6725 1.4133C14.6271 1.30063 14.5604 1.19823 14.4763 1.11196C14.3922 1.0257 14.2923 0.957243 14.1824 0.910514C14.0725 0.863785 13.9547 0.839696 13.8357 0.839621C13.7167 0.839546 13.5988 0.863487 13.4888 0.910077C13.3789 0.956668 13.2789 1.02499 13.1947 1.11116L7.72991 6.71034L2.26665 1.11116C2.0966 0.93693 1.86597 0.83905 1.62549 0.83905C1.38501 0.83905 1.15437 0.93693 0.984327 1.11116C0.814281 1.28538 0.71875 1.52169 0.71875 1.76808C0.71875 2.01447 0.814281 2.25078 0.984327 2.425L6.44916 8.02257L0.984327 13.6201C0.900129 13.7064 0.833339 13.8088 0.787771 13.9215C0.742203 14.0343 0.71875 14.1551 0.71875 14.2771C0.71875 14.3991 0.742203 14.5199 0.787771 14.6326C0.833339 14.7453 0.900129 14.8477 0.984327 14.934C1.15437 15.1082 1.38501 15.2061 1.62549 15.2061C1.74456 15.2061 1.86247 15.1821 1.97248 15.1354C2.08249 15.0887 2.18245 15.0203 2.26665 14.934L7.72991 9.3348L13.1947 14.934C13.3648 15.108 13.5953 15.2057 13.8357 15.2055C14.076 15.2054 14.3064 15.1074 14.4763 14.9332C14.6461 14.7589 14.7414 14.5227 14.7413 14.2765C14.7411 14.0302 14.6455 13.7942 14.4755 13.6201L9.01065 8.02257L14.4755 2.425Z"
                fill={color}
            />
        </svg>
    );
};

export default CloseIcon;
