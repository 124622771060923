import { IconProps } from './types';

const AttentionIcon: React.FC<IconProps> = ({
    width,
    height = '100%',
    color = 'black',
}) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.1667 13.1667H10.8334V6.16671H13.1667M13.1667 17.8334H10.8334V15.5H13.1667M12 0.333374C10.468 0.333374 8.95087 0.635141 7.5354 1.22145C6.11994 1.80775 4.83381 2.66711 3.75046 3.75046C1.56254 5.93839 0.333374 8.90585 0.333374 12C0.333374 15.0942 1.56254 18.0617 3.75046 20.2496C4.83381 21.333 6.11994 22.1923 7.5354 22.7786C8.95087 23.3649 10.468 23.6667 12 23.6667C15.0942 23.6667 18.0617 22.4375 20.2496 20.2496C22.4375 18.0617 23.6667 15.0942 23.6667 12C23.6667 10.468 23.3649 8.95087 22.7786 7.5354C22.1923 6.11994 21.333 4.83381 20.2496 3.75046C19.1663 2.66711 17.8801 1.80775 16.4647 1.22145C15.0492 0.635141 13.5321 0.333374 12 0.333374Z"
                fill={color}
            />
        </svg>
    );
};

export default AttentionIcon;
