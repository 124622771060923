import axiosInstance from './axiosConfig';

export type Flags = { maintenance_mode?: boolean; readonly_mode?: boolean };

interface Response {
    data: Flags;
}

const getServerFlags = (): Promise<Response> => axiosInstance.get(`/wt/flags/`);

export default getServerFlags;
