import { RuntimeConfig } from 'types/runtime-config';

export const runtimeConfigStorageName = 'runtime-config';

export const setCachedRuntimeConfig = (config: RuntimeConfig) => {
    if (!global.localStorage) return;

    localStorage.setItem(runtimeConfigStorageName, JSON.stringify(config));
};

export const getCachedRuntimeConfig = (): RuntimeConfig => {
    if (!global.localStorage) return {};

    const config = localStorage.getItem(runtimeConfigStorageName);

    if (!config) return {};

    return JSON.parse(config);
};
