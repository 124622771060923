import { styled } from '@mui/material';

export const Background = styled('div')({
    width: '100%',
    display: 'flex',
    padding: '14px 47px 14px 34px',
    boxShadow: '0px 4px 20px rgba(17, 24, 33, 0.25)',
});

export const Wrapper = styled('div')(() => ({
    display: 'flex',
    gap: '20px',
    flexGrow: '1',
}));

export const Content = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    gap: '20px',
}));

export const ContentBody = styled('div')(() => ({
    display: 'flex',
    width: '95%',
    flexDirection: 'column',
}));

export const Title = styled('div')(({ theme }) => ({
    ...theme.fonts.body_1,
    color: theme.colors.primaryText,
}));

export const Description = styled('div')(({ theme }) => ({
    ...theme.fonts.body_2,
    color: theme.colors.secondaryText,
}));
