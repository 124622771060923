import { Environment } from 'constants/domains';
import useClientRuntimeConfig from 'state/useClientRuntimeConfig';

const useEnvironment = () => {
    const { config } = useClientRuntimeConfig();
    const { WEBSITE_ENVIRONMENT: env } = config;

    let isGloPros = env === Environment.GloProps;
    const isGlobalProfessionals = env === Environment.GlobalProfessionals;

    if(!isGloPros && !isGlobalProfessionals){
        isGloPros = true;
    }

    return { isGloPros, isGlobalProfessionals };
};

export default useEnvironment;
