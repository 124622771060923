import * as Sentry from '@sentry/nextjs';
import { AppProps } from 'next/app';
import '../index.scss';
import 'react-toastify/dist/ReactToastify.css';
import React, { useEffect, useState } from 'react';
import getMyData from 'api/getMyData';
import { useUserData } from 'state/userData';
import useSWR from 'swr';
import { useRouter } from 'next/router';
import Providers from 'app/providers';
import Favicon from 'components/Favicon';
import useUTMHandler from '@hooks/useUTMtags';

function MyApp({ Component, pageProps }: AppProps) {
    const router = useRouter();
    const { setUserData, setLoginStatus } = useUserData();

    const [isRedirectedToCvUpload, setIsRedirectedToCvUpload] = useState(false);

    const userFetcher = async () => {
        const { data } = await getMyData();
        return data;
    };

    const { data: userData } = useSWR('/me/', userFetcher, {
        revalidateOnFocus: false,
        refreshInterval: 1200000,
    });

    useUTMHandler();

    useEffect(() => {
        if (!userData) {
            setLoginStatus(false);
            return Sentry.setContext('User Info', {
                userType: 'not logged in',
            });
        }

        setLoginStatus(true);

        const talentWithoutCV =
            userData.type === 'talent' &&
            !userData.profile.cv_file &&
            !isRedirectedToCvUpload;

        if (talentWithoutCV) {
            router.push('/sign-up/talent/2');
            setIsRedirectedToCvUpload(true);
        }

        setUserData({
            firstName: userData.first_name,
            lastName: userData.last_name,
            email: userData.email,
            phoneNumber: userData.phone_number,
            username: userData.username,
            location: userData.location,
            type: userData.type,
            role: userData.role,
            id: userData.id,
            profile: userData.profile,
            hasSmsVerification: userData.oob,
            hasAuthenticator: userData.otp,
            companySlug: userData.company_id,
            typeGroups: userData.groups,
            initials: userData.initials,
        });

        const sentryLogData = {
            firstName: userData.first_name,
            lastName: userData.last_name,
            email: userData.email,
            userType: userData.type,
            userSlug: userData.slug || userData.anonymous_profile?.slug,
        };

        Sentry.setContext('User Info', sentryLogData);
    }, [userData]);

    return (
        <Providers>
            <Favicon />

            <Component {...pageProps} />
        </Providers>
    );
}

export default MyApp;
