import { Fragment, ReactNode, useEffect } from 'react';
import { RuntimeConfig } from '../types/runtime-config';
import { isEqual } from 'lodash';
import useSWR from 'swr';
import useClientRuntimeConfig from './useClientRuntimeConfig';
import {
    getCachedRuntimeConfig,
    setCachedRuntimeConfig,
} from 'utils/runtimeConfigUtils';
import axios from 'axios';

export interface Props {
    children: ReactNode;
}

const RuntimeConfigProvider = ({ children }: Props) => {
    const { config: clientConfig, setConfig } = useClientRuntimeConfig();
    const cashedConfig = getCachedRuntimeConfig();

    const fetcher = async () => {
        const fetchUrl = '/api/runtime-config';
        const request = axios.get<RuntimeConfig>(fetchUrl);

        const { data } = await request;
        return data;
    };

    const { data: fetchedConfig } = useSWR('config', fetcher);

    useEffect(() => {
        if (!global.window || !fetchedConfig) return;

        if (!isEqual(clientConfig, fetchedConfig)) setConfig(fetchedConfig);

        if (isEqual(fetchedConfig, cashedConfig)) return;

        setCachedRuntimeConfig(fetchedConfig);
    }, [fetchedConfig, global.window, cashedConfig]);

    return <Fragment>{children}</Fragment>;
};

export default RuntimeConfigProvider;
